import {FlexSlider, appendTextCard} from "./flexslider";
import {getQuestionsForProfile} from "./questionary-repository";
import imageTwoPartyRelation from "/img/2-party-relation.png";
import imageThreePartyRelation from "/img/3-party-relation.png";

export var cardData = [];

export let flow_config = {
  active: "",
  purchaser: {
    id: "purchaser",
    flows: [
      {
        "id": "purchaser-1",
        "text": "Der Experte/Die Expertin hat eine direkte Vertragsbeziehung mit dem Kunden. Die Agentur bietet nur Suche und Vermittlung sowie zusätzliche Services z.B. für Fakturierung und Inkasso an.",
        "buttonText": "Zweipersonenverhältnis",
        "image": "2-party-relation.png"
      },
      {
        "id": "purchaser-2",
        "text": "Der Experte/Die Expertin wird vertraglich vom Projektvermittler bezogen, der sich selbst zur Leistung verpflichtet hat und ihn oder sie zur Verfügung stellt.",
        "buttonText": "Dreipersonenverhältnis",
        "image": "3-party-relation.png"
      }
    ]
  },
  freelancer: {
    id: "freelancer",
    flows: [
      {
        "id": "freelancer-1",
        "text": "Der Experte/Die Expertin hat eine direkte Vertragsbeziehung mit dem Kunden. Die Agentur bietet nur Suche und Vermittlung sowie zusätzliche Services z.B. für Fakturierung und Inkasso an.",
        "buttonText": "Zweipersonenverhältnis",
        "image": "2-party-relation.png"
      },
      {
        "id": "freelancer-2",
        "text": "Der Experte/Die Expertin wird vertraglich vom Projektvermittler bezogen, der sich selbst zur Leistung verpflichtet hat und ihn oder sie zur Verfügung stellt.",
        "buttonText": "Dreipersonenverhältnis",
        "image": "3-party-relation.png"
      }
    ]
  },
  hr: {
    id: "hr-employee",
    flows: [
      {
        "id": "hr-employee-1",
        "text": "Der Experte/Die Expertin hat eine direkte Vertragsbeziehung mit dem Kunden. Die Agentur bietet nur Suche und Vermittlung sowie zusätzliche Services z.B. für Fakturierung und Inkasso an.",
        "buttonText": "Zweipersonenverhältnis",
        "image": "2-party-relation.png"
      },
      {
        "id": "hr-employee-2",
        "text": "Der Experte/Die Expertin wird vertraglich vom Projektvermittler bezogen, der sich selbst zur Leistung verpflichtet hat und ihn oder sie zur Verfügung stellt.",
        "buttonText": "Dreipersonenverhältnis",
        "image": "3-party-relation.png"
      }
    ]
  },
  manager: {
    id: "manager",
    flows: [
      {
        "id": "manager-1",
        "text": "Der Experte/Die Expertin hat eine direkte Vertragsbeziehung mit dem Kunden. Die Agentur bietet nur Suche und Vermittlung sowie zusätzliche Services z.B. für Fakturierung und Inkasso an.",
        "buttonText": "Zweipersonenverhältnis",
        "image": "2-party-relation.png"
      },
      {
        "id": "manager-2",
        "text": "Der Experte/Die Expertin wird vertraglich vom Projektvermittler bezogen, der sich selbst zur Leistung verpflichtet hat und ihn oder sie zur Verfügung stellt.",
        "buttonText": "Dreipersonenverhältnis",
        "image": "3-party-relation.png"
      }
    ]
  }
}

export function loadCardData(flowId) {
  console.log("Loading card data for questionaryId=" + flowId)

  getQuestionsForProfile(flowId)
    .then(response => response.json())
    .then(
      json => {
        console.log(json);
        json.filter(entry => !entry.is_only_topic_and_common_recommendation && entry.id)
          .forEach(card => appendTextCard(card.question, card.explanation_to_rate, flowId + "-" + card.id, card.icon))
      }
    );
}

export function registerSetupCardEvents() {
  // add click listen for profiles, this stars creation of the job relation cards
  document.querySelectorAll(".card-job-profile").forEach(function (card) {
    card.addEventListener('click', (card) => {

      const loadingSpinnerDiv = document.querySelector("#loading-spinner");
      loadingSpinnerDiv.classList.add("is-active")

      const idSeparator = "-";
      const jobProfilePrefix = card.target.id.indexOf(idSeparator);
      const jobProfile = card.target.id.substring(0, jobProfilePrefix);

      console.debug("Looking up for profile=" + jobProfile)
      // flow must exist and not be active
      if (flow_config[jobProfile] && !flow_config.active) {
        flow_config.active = flow_config[jobProfile];
        console.debug("Loading flow for profile=" + JSON.stringify(flow_config[jobProfile]));
        appendJobRelationCard(flow_config[jobProfile]);
        FlexSlider.gotoNext();

        FlexSlider.removeFirstElementSliderItem();
      } else {
        if (flow_config.active) {
          console.debug("Flow with id=" + flow_config.active + " is already active");
        } else {
          console.error("Flow does not exist")
        }
      }

      setTimeout(function () {
        loadingSpinnerDiv.classList.remove("is-active")
      }, 1000);

    });
  });
}


function appendJobRelationCard(flow_profile_config) {
  console.log('Appending job relation card for profile=' + flow_profile_config.id);

  const rootDiv = document.createElement('div');

  // questions
  const questionColumns = document.createElement('div');
  questionColumns.classList.add('columns')

  const divQuestion = document.createElement('div');
  divQuestion.classList.add('column', 'is-size-4');
  divQuestion.textContent = 'Wie erfolgt die Einbindung des Experten/der Expertin beim Kunden?';

  questionColumns.appendChild(divQuestion);

  // relation
  const relationColumns = document.createElement('div');
  relationColumns.classList.add('columns', 'has-text-centered');


  flow_profile_config.flows.forEach(flow => {
    const rateDiv = document.createElement('div');
    rateDiv.classList.add('column');

    const card = document.createElement('div');
    card.classList.add('card');

    const cardImage = document.createElement('div');
    cardImage.classList.add('card-image');
    const figure = document.createElement('figure')
    figure.classList.add('image', 'is-2by1')
    const image = document.createElement('img');

    if (flow.image === "2-party-relation.png") {
      image.src = imageTwoPartyRelation;
    } else if (flow.image === "3-party-relation.png") {
      image.src = imageThreePartyRelation;
    } else {
      console.error("No image for "+flow.image+" found.");
    }

    image.alt = 'purchaser'
    figure.appendChild(image)
    cardImage.appendChild(figure)

    const cardContent = document.createElement('div');
    cardContent.classList.add('content');
    const textP = document.createElement('p');
    textP.classList.add('job-relation-card-text')
    const text = document.createElement('div');
    textP.appendChild(text)
    text.textContent = flow.text;
    cardContent.appendChild(textP)

    const buttonP = document.createElement('p');
    const button = document.createElement('button');
    button.classList.add('button', 'is-medium', 'is-rounded');
    button.id = flow.id;
    button.textContent = flow.buttonText;
    button.addEventListener('click', function (event) {
      const loadingSpinnerDiv = document.querySelector("#loading-spinner");
      loadingSpinnerDiv.classList.add("is-active")

      console.log("Clicked card id=" + event.target.id);
      loadCardData(flow.id);
      FlexSlider.gotoNext();
      FlexSlider.survey_cards_loaded = true;

      FlexSlider.removeFirstElementSliderItem()

      setTimeout(function () {
        loadingSpinnerDiv.classList.remove("is-active")
      }, 1000);
    });
    buttonP.appendChild(button)
    cardContent.appendChild(buttonP)

    card.appendChild(cardImage)
    card.appendChild(cardContent)
    rateDiv.appendChild(card)
    relationColumns.appendChild(rateDiv);
  });

  // add to root div
  rootDiv.appendChild(questionColumns);
  rootDiv.appendChild(relationColumns)
  //rootDiv.appendChild(explanationToRateColumns)

  FlexSlider.appendElementSliderItem(rootDiv);

  //update progress
  document.querySelector("#progress-bar-survey").dispatchEvent(new CustomEvent('updateProgressBarEvent'));
}

registerSetupCardEvents()
