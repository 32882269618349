import {evaluateAnswers} from "./questionary-repository";
import {writeAnswersToStorage} from "./browser-repository";

const question_image_download_url = process.env.URL_BACKEND_API+"/static/icons/"

function addSliderCardItem(divSliderContainer, divCardPayload) {
  const divSliderItem = document.createElement('div');
  divSliderItem.classList.add('card', 'slider-item')
  const dataPositionAttribute = document.createAttribute('data-position');
  dataPositionAttribute.value = FlexSlider.num_items.toString();
  divSliderItem.attributes.setNamedItem(dataPositionAttribute);

  divSliderItem.style.order = FlexSlider.num_items.toString();

  divSliderContainer.appendChild(divSliderItem)

  const divCardContent = document.createElement('div');
  divCardContent.classList.add('card-content')

  divSliderItem.appendChild(divCardContent)

  const divContent = document.createElement('div');
  divContent.classList.add('content')

  divCardContent.appendChild(divContent)
  divContent.appendChild(divCardPayload)
}

export const FlexSlider = {
  // total no of items
  num_items: document.querySelectorAll(".slider-item").length,

  // position of current item in view
  current: 1,

  // stack that stores all clicked cards
  clicked_stack: [],

  // indicate if the progress bar should move
  // entry questions like choice of the flow should not be
  // reflected in progress
  survey_cards_loaded: false,

  init: function () {
    // set CSS order of each item initially
    document.querySelectorAll(".slider-item").forEach(function (element, index) {
      element.style.order = index + 1;
    });

    this.addEvents();
    this.updateProgressBar();
  },

  addEvents: function () {
    var that = this;

    // click on move item button
    document.querySelector("#move-button").addEventListener('click', () => {
      this.gotoNext();
    });

    // after each item slides in, slider container fires transitionend event
    document.querySelector("#slider-container").addEventListener('transitionend', () => {
      this.changeOrder();
    });
  },

  changeOrder: function () {
    // change current position
    if (this.current === this.num_items)
      this.current = 1;
    else
      this.current++;

    this.refreshSliderDataOrder();

    // translate back to 0 from -100%
    // we don't need transitionend to fire for this translation, so remove transition CSS
    document.querySelector("#slider-container").classList.remove('slider-container-transition');
    document.querySelector("#slider-container").style.transform = 'translateX(0)';

    // notify all registered handler
    this.updateProgressBar();
  },

  gotoNext: function () {
    // translate from 0 to -100%
    // we need transitionend to fire for this translation, so add transition CSS
    document.querySelector("#slider-container").classList.add('slider-container-transition');
    document.querySelector("#slider-container").style.transform = 'translateX(-100%)';
  },

  appendElementSliderItem: function (divElement) {
    // increase the total number of elements
    this.num_items++;

    // add card to slider container
    addSliderCardItem(document.querySelector("#slider-container"), divElement);

    this.refreshSliderDataOrder();
    this.updateProgressBar();
  },

  removeFirstElementSliderItem: function () {
    console.log("Current cards position is=" + this.current);

    // add card to slider container
    const sliderContainer = document.querySelector("#slider-container");

    sliderContainer.children[0].remove();

    //decrease the total number of elements
    this.num_items--;
    // skip if the current position is the first card
    if (this.current > 1) {
      console.debug("Decreasing current position");
      this.current--;
    }

    // recalculate the number of cards (data-position)
    for (let i = 0; i < sliderContainer.children.length; i++) {
      console.log(sliderContainer.children[i]);
      sliderContainer.children[i].setAttribute('data-position', (i + 1).toString());
    }

    this.refreshSliderDataOrder();
    this.updateProgressBar();

    console.debug("Now we got num of cards=" + this.num_items);
    console.debug("Now we are at position=" + this.current);
  },

  refreshSliderDataOrder() {
    // fix card order from current to last position
    let order = '1'
    for (let i = this.current; i <= this.num_items; i++) {
      if(!document.querySelector(".slider-item[data-position='" + i + "']")) {
        continue;
      }

      document.querySelector(".slider-item[data-position='" + i + "']").style.order = order;
      order++;
    }

    // fix card order from first to current position
    for (let i = 1; i < this.current; i++) {
      if(!document.querySelector(".slider-item[data-position='" + i + "']")) {
        continue;
      }

      document.querySelector(".slider-item[data-position='" + i + "']").style.order = order;
      order++;
    }
  },

  getProgressInfo: function () {
    console.debug("Progressbar is active=" + this.survey_cards_loaded);
    console.debug("Current card position=" + this.current + " and total num of cards=" + this.num_items)

    const hasNext = () => {
      return this.current !== this.num_items;
    };

    // avoid div with zero and don't display progress questions are not loaded
    //
    const progressInPercent = this.num_items === 0 || !this.survey_cards_loaded ? 0 : (this.current - 1) / this.num_items * 100

    return {"text": Math.floor(progressInPercent), "hasNext": hasNext()};
  },

  updateProgressBar: function () {
    const divSurveyProgress = document.querySelector("#progress-bar-survey");
    const progressPercentageAsString = FlexSlider.getProgressInfo().text.toString();

    divSurveyProgress.setAttribute("value", progressPercentageAsString);
    divSurveyProgress.setAttribute("max", "100");
    divSurveyProgress.textContent = progressPercentageAsString;
  }
};

document.querySelector("#add-column").addEventListener('click', () => {
  console.debug("Current num of cards=" + FlexSlider.num_items)

  const count = FlexSlider.num_items + 1

  const divColumns = document.createElement('div');
  divColumns.classList.add('columns')

  const divColumn = document.createElement('div');
  divColumn.classList.add('column')
  divColumn.textContent = count.toString();

  divColumns.appendChild(divColumn);

  FlexSlider.appendElementSliderItem(divColumns)

  console.debug("Increasing to num of cards=" + FlexSlider.num_items)

  // update progress
  document.querySelector("#progress-bar-survey").dispatchEvent(new CustomEvent('updateProgressBarEvent'))
});

document.querySelector("#remove-first-card").addEventListener('click', () => {
  console.debug('Removing card....');
  FlexSlider.removeFirstElementSliderItem()
});

document.querySelector("#reset-cards").addEventListener('click', () => {
  console.debug('Resetting cards....');
  location.reload();
});

export function appendTextCard(questionText, explanationToRateText, id, iconFilename) {
  console.log("Appending card with id=" + id);
  const count = FlexSlider.num_items + 1;

  const rootDiv = document.createElement('div');

  // questions
  const questionColumns = document.createElement('div');
  questionColumns.classList.add('columns');

  // question
  const divQuestion = document.createElement('div');
  divQuestion.classList.add('column', 'is-size-4');
  divQuestion.textContent = questionText;

  questionColumns.appendChild(divQuestion);

  // rates
  const ratesColumns = document.createElement('div');
  ratesColumns.classList.add('columns', 'has-text-centered', 'is-vcentered');

  // icon
  const divQuestionIcon = document.createElement('div');
  divQuestionIcon.classList.add('column');
  const divQuestionIconFigure = document.createElement('figure');
  divQuestionIconFigure.classList.add('image', 'is-128x128', 'is-inline-block');
  const divQuestionIconFigureImg = document.createElement('img');
  divQuestionIconFigureImg.src = question_image_download_url+iconFilename+".png";
  divQuestionIconFigure.appendChild(divQuestionIconFigureImg);
  divQuestionIcon.appendChild(divQuestionIconFigure);
  ratesColumns.appendChild(divQuestionIcon);

  ['1', '2', '3', '4', '5'].forEach(rate => {
    const rateDiv = document.createElement('div');
    rateDiv.classList.add('column');

    const button = document.createElement('button');
    button.classList.add('button', 'is-medium', 'is-rounded');
    button.id = id + "-" + rate;
    button.textContent = rate;
    button.addEventListener('click', function (event) {
      console.log('Clicked card id=' + event.target.id);
      FlexSlider.clicked_stack.push(event.target.id)
      if (FlexSlider.getProgressInfo().hasNext) {
        FlexSlider.gotoNext();
      } else {
        evaluateAnswers(FlexSlider.clicked_stack)
          .then(response => response.json())
          .then(response => writeAnswersToStorage(response))
          .then(persistence_id => location.href = 'evaluation-report.html?answerUuid=' + persistence_id)
          .catch(error => console.error('Error:', error))
      }
    });

    rateDiv.appendChild(button)
    ratesColumns.appendChild(rateDiv)
  })

  // explanation to rate
  const explanationToRateColumns = document.createElement('div');
  explanationToRateColumns.classList.add('columns')

  const explanationToRateDiv = document.createElement('div');
  explanationToRateDiv.classList.add('column')

  const explanationToRateTitle = document.createElement('p');
  explanationToRateTitle.classList.add('is-size-4')
  explanationToRateTitle.textContent = "Bewertungsmaßstab:"
  explanationToRateDiv.appendChild(explanationToRateTitle)

  const explanationToRateContent = document.createElement('p');
  explanationToRateContent.textContent = explanationToRateText
  explanationToRateDiv.appendChild(explanationToRateContent)

  explanationToRateColumns.appendChild(explanationToRateDiv);

  // add to root div
  rootDiv.appendChild(questionColumns)
  rootDiv.appendChild(ratesColumns)
  rootDiv.appendChild(explanationToRateColumns)

  FlexSlider.appendElementSliderItem(rootDiv)

  // update progress
  document.querySelector("#progress-bar-survey").dispatchEvent(new CustomEvent('updateProgressBarEvent'))
}

console.log('Initialising...')

FlexSlider.init();
