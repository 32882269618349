
export function writeAnswersToStorage(answers) {
  console.debug("Got response id="+JSON.stringify(answers))
  writeBase64ToLocalStorage(answers.persistence_id, encodeObject(answers.evaluation_response))
  return answers.persistence_id
}

export function readAnswersFromStorage(storageId) {
  console.debug("Reading answers with id="+storageId)
  return decodeObject(readBase64FromLocalStorage(storageId))
}

function encodeObject(plainObj) {
  return btoa(unescape(encodeURIComponent(JSON.stringify(plainObj))))
}

function decodeObject(encObj) {
  console.debug("Decoding string: "+encObj);

  return JSON.parse(decodeURIComponent(escape(atob(encObj))));
}

function writeBase64ToLocalStorage(peristanceId, base64Str) {
  sessionStorage.setItem(peristanceId, base64Str)
}

function readBase64FromLocalStorage(storageKeyUUID) {
  const storageItem = sessionStorage.getItem(storageKeyUUID)
  if(!storageKeyUUID) {
    console.error("No item to load from storage.")
  }
  return storageItem;
}

function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
