// parcel must include the csvs to its build process
import 'regenerator-runtime/runtime'

/**
 * Evaluation of answers given by the user.
 * @param answers the answers as array of strings eg:
 * [purchaser-1-2-3,purchaser-1-3-4]
 */
const survey_backend_base_url = process.env.URL_BACKEND_API

export async function evaluateAnswers(answers) {
  console.debug("Sending answers for evaluation:", answers);

  // call backend to evaluate results
  const response = await fetch(survey_backend_base_url + '/v1/survey/evaluate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(answers),
  });

  return response;
}

export async function getQuestionsForProfile(flowId) {
  console.debug("Getting questions from server for profile:", flowId);

  const response = await fetch(survey_backend_base_url + '/v1/survey/flow/'+flowId)
  return response;
}
